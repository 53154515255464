<template>
  <div>
    <ul>
      <div class="line"></div>
      <div class="front">
        <div v-for="(item, index) in list.length - 1" :key="index + item" class="">
          〉
        </div>
      </div>
      <li v-for="(item, index) in list" :key="index + item" :class="{ blackIndex: index < nowIndex, nowIndex: index == nowIndex }">
        <div class="index">{{ index + 1 }}</div>
        <div class="title">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'formLine',
  data() {
    return {}
  },
  props: {
    list: Array,
    nowIndex: Number
  }
}
</script>

<style scoped lang="less">
ul {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 20px;
  overflow: hidden;
  .front {
    width: 100%;
    position: absolute;
    padding-left: 20px;
    left: 0;
    top: 40px;
    display: flex;
    div {
      flex: 1;
      text-align: center;
      color: #e9e9e9;
    }
  }
  .line {
    height: 1px;
    background: #e6e7ee;
    width: 96%;
    position: absolute;
    left: 0;
    top: 15px;
    margin-left: 40px;
  }
  li:after {
    content: '';
    flex: 1;
  }
  .blackIndex {
    .title {
      color: #525b6d;
      font-weight: 600;
    }
    .index {
      color: #fff;
      background: #525b6d;
      border: 1px solid #525b6d;
    }
  }
  .nowIndex {
    .title {
      color: #389afc;
      font-weight: 600;
    }
    .index {
      color: #fff;
      background: #389afc;
      border: 1px solid #389afc;
    }
  }
  li {
    text-align: center;
    color: #cccccc;
    font-size: 14px;
    z-index: 2;
    position: relative;
    .index {
      display: inline-block;
      height: 30px;
      line-height: 26px;
      text-align: center;
      width: 30px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 2px solid #e9e9e9;
      margin-bottom: 8px;
      background: #fff;
      z-index: 100;
    }
  }
}
</style>
